import $ from "jquery";

const addFieldCoursesEventExercise = () => {
  $("form").on("click", ".add_course_events_exercises_fields", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    $(".course_events_exercises_fields").append($(this).data("fields").replace(regexp, time));
    $(".course_events_exercises_fields").find('.select2').select2();

    $(".course_events_exercises_fields").find("> tr:last > td > input.order_field").each(function(index) {
      $(this).val($(".course_events_exercises_fields").find("> tr").length);
    });

    return event.preventDefault();
  });
};

export { addFieldCoursesEventExercise };
